import { useCallback, useEffect, useState } from "react";
import { ruleAPI } from "../../../../../utils/api";
import { noteTypesAPI } from "../../../../../utils/api/NoteTypeApi";
import { CodeResult } from "../CodeResult/CodeResult";
import Result from "./Result/Result";
import "./Results.scss";
import { EmergencyCodeResult } from "../../../Emergency/EmergencyResult";
import { DraftPreviewModal } from "../../../../../stateOffice/pages/WorkQueue/components/DraftPreviewModal";

export default function Results(props: any) {
  // console.log(props.query == "*");
  const [expanded, setExpanded] = useState("");
  const [agencyList, setAgencyList]: any = useState();
  const [noteTypesList, setNoteTypesList]: any = useState();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [draftData, setDraftData] = useState<any>(undefined);

  let results = props.documents.map((result: { document: any }, index: any) => {
    return (
      <Result key={index} document={result.document} query={props.query} />
    );
  });

  const onMount = useCallback(async () => {
    // console.log("onMount", props.q);
    if (agencyList === undefined) {
      const agencies: any = await ruleAPI.getAllRules({
        pageNumber: 1,
        pageSize: 200,
      }, false);
      if (agencies) {
        let result: [] = agencies;
        setAgencyList(result);
      }
    }

    let noteTypes = await noteTypesAPI.getAllNoteTypes();
    setNoteTypesList(noteTypes);

  }, []);

  useEffect(() => {
    // console.log("Results checked: ", props.proposedChecked, props.q, results);
    onMount();
  }, [onMount, props.query]);

  const toggleDraftModal = async (id?: number) => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);
  };

  const code = () => {
    return <CodeResult agencyList={agencyList} noteTypes={noteTypesList} />;
  };



  const emergencyCode = () => {
    return (
      <>
        <DraftPreviewModal
          open={modalOpen}
          draftData={draftData}
          close={toggleDraftModal}
          source = "emergency"
        />
        <EmergencyCodeResult toggleDraftModal ={toggleDraftModal} setDraftData={setDraftData} draftData={draftData}/>;
      </>
    );
  };

  let beginDocNumber = Math.min(props.skip + 1, props.count);
  let endDocNumber = Math.min(props.skip + props.top, props.count);

  return (
    <div>
      {props.query !== "" ?
        (
          <div className="results-info">
            <p>
              Showing {beginDocNumber}-{endDocNumber} of{" "}
              {props.count.toLocaleString()} results
            </p>
          </div>
        ) : null
      }
      <div className="row row-cols-md-5 results">
        {props.query
          ? results
            ? results
            : code()
          : props.emergencyChecked === true && props.q !== ""
          ? emergencyCode()
          : code()}
        {/* {props.emergencyChecked === true && props.q !== "" ? emergencyCode() : code()} */}
        {/* {props.emergencyChecked === false && props.query && results  ? code(): "Test3"} */}
      </div>
    </div>
  );
}
