/**
 * The SplitScreen component is a container for the SegmentHierarchy and EditForm components.
 * It uses the Split component to split the screen into two sections.
 * The first section is the SegmentHierarchy component, which displays the segment hierarchy.
 * The second section is the EditForm component, which displays the edit form.
 * The Split component is configured to split the screen into two sections,
 * with the first section taking up 70% of the screen and the second section taking up 30%.
 * The Split component is configured to start in
 */

import { useContext, useEffect, useState } from "react";
import Split from "react-split";
import { SegmentHierarchy } from "../../../../../components/FilingHome/components/ManageTab/Components/SegmentHierarchy";
import {
  draftAPI,
  filingAPI,
  segmentAPI,
  segmentHistoryAPI,
  segmentTypeAPI,
  statusAPI,
} from "../../../../../utils/api";
import {
  Draft,
  Filing,
  Segment,
  SegmentType,
} from "../../../../../utils/model";
// import { DraftEditForm } from "../../../";
import { DraftHierarchy } from "../DraftHierarchy";
import { DraftPreviewModal } from "../DraftPreviewModal";

// import { EditFormButton } from "../EditForm/EditFormButton";
import "./SplitScreen.scss";
import { Button, Snackbar } from "@material-ui/core";
import {
  Toolbar,
  ToolbarItem,
  ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import { setUncaughtExceptionCaptureCallback } from "process";
import { SnackbarContent } from "../SnackbarAlert/SnackBarStyle";
import Alert from "@material-ui/lab/Alert";
import { DraftEditForm } from "../../../../../components";
import { CodeHierarchy } from "../../../../../components/CodeHierarchy";
import { DraftModal } from "../DraftModal";
import { FilingTypes, StatusTypes } from "../../../../../utils/enums";
import { AuthenticationContext } from "../../../../../utils/authentication/AuthenticationProvider";
import { stat } from "fs";

interface IEditRule {
  draft: Draft;
  reloadData: () => void;
}

export const SplitScreen = (props: IEditRule) => {
  const [collapsedIndex, setCollapsedIndex] = useState(0);
  const [editButtonValue, setEditButtonValue] = useState(0);
  const [segmentData, setSegmentData] = useState<any>([]);
  const [segmentTypes, setSegmentTypes] = useState<SegmentType[]>([]);
  const [toggleExpanded, setToggleExpanded] = useState<boolean>(false);
  const [editSegmentData, setEditSegmentData] = useState<any>();
  const [splitSize, setSplitSize] = useState<any>([100, 0]);
  const [draftHistoryData, setDraftHistoryData] = useState<any>([]);
  const [addSegmentData, setAddSegmentData] = useState<any>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [originalSegment, setOriginalSegment] = useState<any>(null);
  const [openErrorSnack, setOpenErrorSnack] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openDraft, setOpenDraft] = useState<boolean>(false);
  const { getUser } = useContext(AuthenticationContext);

  let minSize = 0;

  const onMount = async () => {
    setEditSegmentData(null);
    setAddSegmentData(null);
    // Get all segment history items
    // console.log("On mount getting hit");
    let segmentHistoryItems =
      await segmentHistoryAPI.GetSegmentHistoryByDraftId(props.draft.id!);
    setDraftHistoryData(segmentHistoryItems);

    // Get chapter segment from title and chapter number
    let segments = await segmentAPI.getChapterSegment(
      props.draft.titleNumber,
      props.draft.chapterNumber
    );

    setSegmentData(segments)

    // Get segment type list for hierarchy
    let segmentTypeList = await segmentTypeAPI.getAllSegmentTypes();
    setSegmentTypes(segmentTypeList);
  };

  useEffect(() => {
    if (props.draft) {
      onMount();
    }
  }, [props.draft]);

  /**
   * @description - this will fetch new draft history data and will cause the hierarchy to reload where the hierarchy has a history dependency that refreshes components on state change
   */
  const reloadHierarchy = async () => {
    // Get chapter segment from title and chapter number
    let segments = await segmentAPI.getChapterSegment(
      props.draft.titleNumber,
      props.draft.chapterNumber
    );
    setSegmentData(segments);
    // Get any history items that need render
    let segmentHistoryItems: any =
      await segmentHistoryAPI.GetSegmentHistoryByDraftId(props.draft.id!);
    segmentHistoryItems.sort(function (a: any, b: any) {
      return a.segmentId - b.segmentId;
    });

    setDraftHistoryData(segmentHistoryItems);
    // console.log("RELOADING PAGE",segmentHistoryItems);
  };

  /**
   * @description - launches modal window to preview the current drafted changes
   * @todo write the rest of the function once draft history is saving
   */
  const previewDraft = () => {
    //Preview draft
    previewOpen ? setPreviewOpen(false) : setPreviewOpen(true);
  };

  /**
   * @description - toggle expand is used to expand or collapse all in the draft heirarchy
   */
  const toggleExpand = () => {
    toggleExpanded ? setToggleExpanded(false) : setToggleExpanded(true);
  };

  const getUserRole = async () => {
    let user = await getUser();
    let role = "Agency"

    if (user) {
      user.profile.role.forEach((element: string) => {
        if (
          element == "Office Of Administrative Rules" ||
          element == "Global User Administrator"
        ) {
          role = "Admin";
          return;
        }
      });
      return role;
    }
  }

  const checkEditSection = async (data: any, segment: any) => {
    // console.log("CHECK EDIT SECTION", data, segment);
    if (data != null) {
      // Check if any filings using the draft are emergencies in an editable status
      try {
        let filings = await filingAPI.getFilingsByDraftId(props.draft.id ?? -1);
        // let userRole = await getUserRole()
        let userRole = "Agency"
        let editAllowed = true;

        for (var i = 0; i < filings.length; i++) {
          if (filings[i].filingTypeId == FilingTypes.Emergency) {
            let status = await (await statusAPI.getStatusesById(filings[i].statusId));
            if (userRole == "Admin") {
              if (status.name == "Codified" || status.name == "Published" || status.name == "Canceled") {
                editAllowed = false;
              } else {
                editAllowed = true;
                break;
              }
            } else {
              // General agency users shouldn't be allowed to edit emergency filings that are either accepted or considered end of life.
              if (status.name == "Approved" || status.name == "Accepted" || status.name == "Codified" || status.name == "Published" || status.name == "Canceled") {
                editAllowed = false;
              } else {
                editAllowed = true;
                break;
              }
            }
          }
        }

        // if we have any filings that are in an editable state or are not emergency filings then allow edits
        if (editAllowed == true) {
          setEditSegmentData(data);
          setOriginalSegment(segment);
          setSplitSize([40, 60]);
          setEditButtonValue(1);
          return;
        } else if (editAllowed == false) {
          setErrorMessage("Draft changes can no longer be made.");
          setOpenErrorSnack(true);
          setEditSegmentData(null);
          setOriginalSegment(null);
          setSplitSize([100, 0]);
          setEditButtonValue(0);
          return;
        }
      } catch (error: any) {
        console.error("Cannot find associated filing or filing status.", error.message)
      }
      setEditSegmentData(data);
      setOriginalSegment(segment);
      setSplitSize([40, 60]);
      setEditButtonValue(1);
    } else {
      setEditSegmentData(null);
      setOriginalSegment(null);
      setSplitSize([100, 0]);
      setEditButtonValue(0);
    }
  };

  /**
   * @description used to add new segment history item
   */
  const addSegmentHistoryItem = (
    type: string,
    event: any,
    segmentData: any
  ) => {
    let value = event.target.buttonsData.find(
      (element: any) => element.text == type
    );
    setAddSegmentData({ type: type, id: value.id, parentSegment: segmentData });
  };

  const openError = (errorMessage?: string) => {
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
    setOpenErrorSnack(true);
  }

  // closes error snack message after alloted time
  const handleSnackClose = () => {
    setOpenErrorSnack(false);
  }

  const handleEditDraftName = () => {
    setOpenDraft(true);
  }

  const toggleDraftModal = () => {
    openDraft ? setOpenDraft(false) : setOpenDraft(true);
  }



  /* The `return` statement is used to return a value from a function. */
  return (
    <Split
      className="split"
      sizes={splitSize}
      collapsed={collapsedIndex}
      minSize={minSize}
    >
      <div>
        <Snackbar
          open={openErrorSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleSnackClose}
        >
          <Alert onClose={handleSnackClose} severity="error">
            {errorMessage != "" ? `Error: ${errorMessage}` : "Error: Failed To Save"}
          </Alert>
        </Snackbar>
        <Toolbar className="draft-toolbar">
          <ToolbarItem>
            <div>
              <b>Draft:</b> <i>{props.draft ? props.draft.draftName : ""}</i> -{" "}
              <b>Title:</b> {props.draft ? props.draft.titleNumber : ""}
            </div>
          </ToolbarItem>
          <ToolbarSpacer />
          <ToolbarItem>
            <Button
              variant="contained"
              size="small"
              className="draft-button"
              onClick={handleEditDraftName}
            >
              Edit Draft Name
            </Button>
            <Button
              variant="contained"
              size="small"
              className="draft-button"
              onClick={toggleExpand}
            >
              {toggleExpanded ? "Collapse" : "Expand"}
            </Button>
            <Button
              variant="contained"
              size="small"
              className="draft-button"
              onClick={previewDraft}
            >
              Preview
            </Button>
          </ToolbarItem>
        </Toolbar>
        <DraftModal id="draftModal" draftData={props.draft} open={openDraft} close={toggleDraftModal} reloadData={props.reloadData} edit={true} />
        <DraftPreviewModal
          open={previewOpen}
          close={previewDraft}
          draftData={props.draft}
        />

        {segmentData[0] ? (
          <CodeHierarchy
            reloadHierarchy={reloadHierarchy}
            // splitValue={splitSize}
            editSection={checkEditSection}
            editStatus={editButtonValue}
            segmentData={segmentData[0]}
            draft={props.draft}
            segmentType={segmentTypes}
            expandAll={toggleExpanded}
            addSegment={addSegmentHistoryItem}
            draftHistoryData={draftHistoryData}
            showWIP={true}
          />
        ) : null}
      </div>
      {/* <div></div> */}
      {editSegmentData ? (
        <DraftEditForm
          sectionNum={0}
          reload={reloadHierarchy}
          edit={editSegmentData}
          draftData={props.draft}
          editSection={checkEditSection}
          originalSegment={originalSegment}
          openError={openError}
        />
      ) : (
        <span></span>
      )}
    </Split>
  );
};
