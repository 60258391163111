import { Draft } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class DraftAPI extends BaseAPI<Draft> {
  constructor() {
    super("draft", APISettings.adminRulesApiUrl);
  }

  public getDraft: (id: number) => Promise<Draft> = async (id: number) => {
    try {
      return (await BaseAPI.axios.get(`${this.apiUrl}/GetDraft?id=${id}`)).data;
    } catch (error) {
      return {};
    }
  };

  public getDraftsWithIdNums: (
    draftId: number,
    registerId: number,
    segmentId: number
  ) => Promise<Draft> = async (
    draftId: number,
    registerId: number,
    segmentId: number
  ) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/getDraftsWithIdNums?draftId=${draftId}&registerId=${registerId}&segmentId=${segmentId}`
      )
    ).data;
  };

  public getDraftWithRegisterId: (id: number) => Promise<Draft> = async (
    id: number
  ) => {
    return (
      await BaseAPI.axios.get(`${this.apiUrl}/GetDraftWithRegisterId?id=${id}`)
    ).data;
  };

  public getAllDrafts: () => Promise<Draft[]> = async () => {
    try {
      let response = (await BaseAPI.axios.get(`${this.apiUrl}/GetAllDrafts`))
        .data;
      return response;
    } catch (error) {
      return [];
    }
  };

  public compileDraft: (id: number) => Promise<any[]> = async (id: number) => {
    try {
      return await BaseAPI.axios.get(
        `${this.apiUrl}/CompileDraft?draftId=${id}`
      );
    } catch (error) {
      return [];
    }
  };

    public compilePublicPreview: (id: number) => Promise<any[]> = async (id: number) => {
    try {
      return await BaseAPI.axios.get(
        `${this.apiUrl}/CompilePublicPreview?draftId=${id}`
      );
    } catch (error) {
      return [];
    }
  };

  public getFilteredDraftsAsync: (
    ruleId: number,
    filterByUserTitleClaim: boolean,
    excludeDraftStatusIds: number[]
  ) => Promise<Draft[]> = async (
    ruleId: number,
    filterByUserTitleClaim: boolean,
    excludeDraftStatusIds: number[]
  ) => {
    if (excludeDraftStatusIds.length > 0) {
      var response = await BaseAPI.axios.get(
        `${
          this.apiUrl
        }/GetFilteredDraftsAsync?ruleId=${ruleId}&FilterByUserTitleClaim=${filterByUserTitleClaim}&ExcludeDraftStatusIds=${excludeDraftStatusIds.join(
          "&ExcludeDraftStatusIds="
        )}`
      );
      return response.data;
    } else {
      var response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetFilteredDraftsAsync?ruleId=${ruleId}&FilterByUserTitleClaim=${filterByUserTitleClaim}`
      );
      return response.data;
    }
  };

  public getProposedRulesByDraftId: (
    draftId: number,
    source: string
  ) => Promise<any[]> = async (draftId: number, source: string) => {
    var response = await BaseAPI.axios.get(
      `${this.apiUrl}/GetProposedRulesByDraftId/${draftId}/${source}`
    );
    return response.data;
  };

  public GetDraftBySegmentId: (segmentId: number) => Promise<any> = async (
    segmentId: number
  ) => {
    var response = await BaseAPI.axios.get(
      `${this.apiUrl}/GetDraftBySegmentId/${segmentId}`
    );
    return response.data;
  };

  public createDraft: (draft: Draft) => Promise<any> = async (draft: Draft) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateDraft`,
      draft
    );
    return response.data;
  };

  public deleteDraft: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.delete(
      `${this.apiUrl}/DeleteDraft?draftId=${id}`
    );
    return response.data;
  };

  public updateDraft: (draft: Draft) => Promise<Draft> = async (
    draft: Draft
  ) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateDraft`,
      draft
    );
    return response.data;
  };

  public updateDraftName: (draftId: number, draftName:string) => Promise<Draft> = async (
    draftId: number, draftName: string
    ) => {
     const response = await BaseAPI.axios.put(`${this.apiUrl}/UpdateDraftName/${draftId}/${draftName}`);
    return response.data;
  }


}
export const draftAPI = new DraftAPI();
