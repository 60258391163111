import {
  Avatar,
  CircularProgress,
  LinearProgress,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { userInfoAPI } from "../../utils/api";
import { SystemUser } from "../../utils/model";
import "./UserAvatar.scss";

interface IUserAvatarProps {
  className?: string;
  userId: string;
  displayText: string;
}

interface PartialUser {
  profile: {
    firstName: string;
    lastName: string;
    photo: string;
  };
}

function isNumeric(value: string) {
  return /^\d+$/.test(value);
}

export const UserAvatar = (props: IUserAvatarProps) => {
  const [user, setUser] = useState<PartialUser | null>(null);
  const { userId, displayText } = props;


  const getUserInfo = useCallback(async () => {
    if (isNumeric(userId)) {
      if (parseInt(userId) === 1) {
        setUser(SystemUser);
      }
    } else if (userId == "System(nightly job)" || userId == "System") {
      setUser(SystemUser);
    } else {
      const userFound = await userInfoAPI.getAvatarInfo(userId);
      setUser(userFound);
    }
  }, [userId]);


  useEffect(() => {

    getUserInfo();
  }, [userId, getUserInfo]);


  if (user != null) {
    const { firstName, lastName, photo } = user.profile;
    return (
      <React.Fragment>
        <ListItemAvatar>
          <Avatar
            tabIndex={0}
            style={{ backgroundColor: "#b9b9b9" }} //required minimum contrast for accesssibility
            alt="System"
            src={photo}
          />
        </ListItemAvatar>
        <ListItemText
          tabIndex={0}
          primary={`${firstName} ${lastName}`}
          secondary={
            <React.Fragment>
              <Typography component="div" variant="body2" color="textPrimary">
                {displayText}
              </Typography>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ListItemAvatar>
          <Avatar
            tabIndex={0}
            style={{ backgroundColor: "#b9b9b9" }} //required minimum contrast for accesssibility
            alt="loading"
            src=""
          >
            <CircularProgress color="secondary" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          tabIndex={0}
          primary={
            <React.Fragment>
              <Typography component="div" variant="body2" color="textPrimary">
                <LinearProgress color="secondary" />
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography component="div" variant="body2" color="textPrimary">
                {displayText}
              </Typography>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
};
